
import {defineComponent, ref, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {TypeTags} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		item: {
			type: Object as PropType<TypeTags.TypeTag>,
			required: true
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();

		const {handleSubmit, handleReset, setValues, values} = useForm();

		const { value: name, errorMessage: nameError } = useField<string>('name', 'required');
		const { value: comment, errorMessage: commentError } = useField<string>('comment');

		const set = () => {
			setValues({
				name: props.item?.name || "",
				comment: props.item?.comment || ""
			});
		}

		set();

		watch(() => props.open, () => set());

		const isLoading = ref(false);
		const service = injectOrThrow<TypeTags.TypeTagService>('TypeTags.TypeTagService');

		const edit = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.edit({
					id: props.item.id,
					name: values.name,
					comment: values.comment
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.edited", { name: ucFirst(t("dictionary.typeTag")) })});
				emit("item-changed");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = async () => {
			emit('dialog-closed');
			handleReset();
		};

		return {
			t,
			name, nameError,
			comment, commentError,
			isLoading, edit, closeModal
		}
	}
})
