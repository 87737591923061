
import {defineComponent, computed, PropType, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import DataTable from '@/components/tables/DataTable.vue';
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import {TypeTags} from "@/models";
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {ucFirst} from '@/helpers/firstRegister';

export default defineComponent({
	emits: ["row-clicked", "page-changed", "item-removed", "list-updated"],
	components: {
		DataTable,
		DataTableHeader
	},
	props: {
		list: {
			type: Object as PropType<TypeTags.TypeTagList>,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const itemActiveId = ref(null as number|null);

		const headers = computed(() => [
			{ text: t("dictionary.name"), value: 'name' },
			{ text: t("dictionary.comment"), value: 'comment' },
			{ text: t("dictionary.status"), value: 'date_time_end', status: {itemActiveId: itemActiveId.value, action: (item: TypeTags.TypeTag) => item.date_time_end ? unblock(item.id) : block(item.id)}, width: "1%" },
			{ classCell: 'pl-0', icon: {name: 'basket', title: `${t("button.remove")} ${t("dictionary.typeTag")}`, action: (item: TypeTags.TypeTag) => emit("item-removed", item)}, width: "1%" },
		])

		const items = computed(() => props.list.items);

		const service = injectOrThrow<TypeTags.TypeTagService>('TypeTags.TypeTagService');

		const block = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.block(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.blocked", { name: ucFirst(t("dictionary.typeTag")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		const unblock = async (id: number) => {
			itemActiveId.value = id;

			try {
				await service.unblock(id);

				store.commit('notification/successMessage', {title: t("form.notify.success.unblocked", { name: ucFirst(t("dictionary.typeTag")) })});
				emit("list-updated");
			} catch (e) {
				return;
			} finally {
				itemActiveId.value = null;
			}
		};

		return { headers, items }
	}
})
